@use "@/styles/mixins/max-root-width" as *;
@use "@/styles/mixins/media-queries" as *;

.container {
  position: relative;
  padding-top: var(--padding-top);

  --padding-top: 2rem;

  @include desktop-and-up {
    --padding-top: 3.5rem;
  };
}

:global {
  @include dark-mode() {
    :local {
      .container {
        --color-pattern: var(--color-pattern-dark);
        --color-text: var(--color-text-dark);
        --color-background: var(--color-background-dark);
      }
    }
  }

  @include light-mode() {
    :local {
      .container {
        --color-pattern: var(--color-pattern-light);
        --color-text: var(--color-text-light);
        --color-background: var(--color-background-light);
      }
    }
  }
}

.video {
  aspect-ratio: 16/9; // size of the video
  @include limit;
  width: 100%;
  position: relative;
  z-index: 2;

  @include mobile-only {
    max-width: 100%;
  }
}

.videoBackdrop {
  --offset: 0;
  position: absolute;
  display: none;
  top: 0;
  left: calc(var(--offset) * -1);
  right: 0;
  bottom: 0;
  background-color: var(--color-background);

  @include tablet-portrait-and-up {
    display: block;
    --offset: 0.5rem;
  }

  @include desktop-and-up {
    --offset: 2rem;
  }
}

.patternContainers {
  position: absolute;
  z-index: 1;
  height: 85%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-background);
  max-height: 690px;

  path {
    fill: var(--color-pattern);
  }
}

.patternContainerLeft, .patternContainerRight {
  position: absolute;
  overflow: hidden;
}

.patternContainerLeft {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include tablet-portrait-and-up {
    width: 40%;
  }
}

.patternContainerRight {
  display: none;
  top: var(--padding-top);
  right: 0;
  width: var(--root-side-padding);
  height: 100%;
  max-height: calc(100% - var(--padding-top));

  @include tablet-portrait-and-up {
    display: block;
  }
}

.pattern {
  height: 100%;
  width: 100%;
}
