@use "@/styles/mixins/card-outline" as *;
@use "@/styles/mixins/media-queries" as *;

.upcomingMatchCard {
  @include card-outline;
  display: block;
  text-decoration: none;
  color: inherit;
  border-radius: var(--rounded-corner);
}

.upcomingMatchCard__imageContainer {
  --icons-offset: 0.625em;
  position: relative;
  border-top-left-radius: var(--rounded-corner);
  border-top-right-radius: var(--rounded-corner);
  overflow: hidden;
}

.upcomingMatchCard__image {
  border-top-left-radius: var(--rounded-corner);
  border-top-right-radius: var(--rounded-corner);
}

.upcomingMatchCard__partnerLogo {
  --size: 1.5em;
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: var(--icons-offset);
  right: var(--icons-offset);

  @include desktop-and-up {
    --size: 1.875em;
  }
}