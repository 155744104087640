@use "@/styles/mixins/media-queries" as *;

.logoLoadingSpinner {
  --logo-spinner-size: 6.25em;
  --logo-spinner-progress-bar-size: 0.25em;

  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  border: var(--logo-spinner-progress-bar-size) solid var(--divider-color);
  border-radius: 50%;
  width: var(--logo-spinner-size);
  height: var(--logo-spinner-size);
  color: var(--active-element-highlight-color);
  filter: var(--active-element-highlight-shadow-filter);

  @include desktop-and-up {
    --logo-spinner-size: 8.75em;
  }
}

.logoLoadingSpinnerProgress {
  width: var(--logo-spinner-size);
  height: var(--logo-spinner-size);
}

.progressBarCircular {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: currentColor;
  overflow: hidden;
}

.progressBarCircular::-webkit-progress-bar {
  background-color: transparent;
}

.progressBarCircular:indeterminate {
  mask-image: linear-gradient(transparent 50%, black 50%),
  linear-gradient(to right, transparent 50%, black 50%);
  animation: progressBarCircularIndeterminate 16s infinite cubic-bezier(0.3, 0.6, 1, 1);
}

@mixin _progressBarCircularIndeterminateStyles {
  box-sizing: border-box;
  border: solid var(--logo-spinner-progress-bar-size) transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: progressBarCircularIndeterminatePseudo 2s infinite linear alternate;
}

.progressBarCircular:indeterminate::before,
.progressBarCircular:indeterminate::-webkit-progress-value {
  @include _progressBarCircularIndeterminateStyles;
  content: "";
  display: block;
}

.progressBarCircular:indeterminate::-moz-progress-bar {
  @include _progressBarCircularIndeterminateStyles;
}

@keyframes progressBarCircularIndeterminate {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  25% {
    transform: rotate(630deg);
  }
  37.5% {
    transform: rotate(810deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(1260deg);
  }
  62.5% {
    transform: rotate(1440deg);
    animation-timing-function: linear;
  }
  75% {
    transform: rotate(1890deg);
  }
  87.5% {
    transform: rotate(2070deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(2520deg);
  }
}

@keyframes progressBarCircularIndeterminatePseudo {
  0% {
    transform: rotate(-30deg);
  }
  29.4% {
    border-left-color: transparent;
  }
  29.41% {
    border-left-color: currentColor;
  }
  64.7% {
    border-bottom-color: transparent;
  }
  64.71% {
    border-bottom-color: currentColor;
  }
  100% {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg);
  }
}

.logoLoadingSpinnerIcon {
  width: 2.25em;

  @include desktop-and-up {
    width: 3.125em;
  }
}
