@use "@/styles/mixins/media-queries" as *;

.container {
  display: grid;
  height: fit-content;
  position: relative;
  gap: 1.25rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}

.list {
  display: grid;
  list-style: none;
  gap: 1.875rem;

  @include tablet-portrait-only {
    margin-bottom: 1.25rem;
    gap: 2.125rem 1rem;
  }

  @include tablet-landscape-and-up {
    gap: 2.125rem;
  }
}

list:hover > .listItem:not(:hover) {
  opacity: 0.5;
}

.listItem {
  transition: opacity 250ms ease-in-out;
}

.seeMoreButton {
  text-decoration: none;
  text-transform: capitalize;
  color: var(--label-text-color);
}
