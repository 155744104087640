.playerLoaderWrapper {
  width: 100%;
  aspect-ratio: 16/9;
  position: absolute;
  z-index: var(--embedded-video-player-index);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-background-color);
}
