@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width";

.upcomingMatches {
  background-color: var(--elevated-background-color);
  padding: 2em 0 1.375em;
}

.upcomingMatchesWithPatterns {
  position: relative;
  padding-top: 6rem;

  @include desktop-and-up {
    padding-top: 2rem;
  }
}

.upcomingMatchesTop {
  @include max-root-width.limit;
  display: grid;
  gap: 1em;
  align-items: center;

  @include tablet-portrait-and-up {
    grid-template-columns: 1fr min-content;
  }
}

.patternContainerLeft, .patternContainerRight {
  --width: 100%;
  position: absolute;
  top: 0;
  width: var(--width);

  path {
    fill: var(--divider-color);
  }

  @include desktop-and-up {
    --width: calc(var(--root-side-padding) - 3.875rem);
  }
}

.patternContainerLeft {
  left: 0;
  height: 4rem;

  @include desktop-and-up {
    bottom: 0;
    height: unset;
  }
}

.patternContainerRight {
  display: none;

  @include desktop-and-up {
    display: block;
    right: 0;
    bottom: 0;
  }
}

.pattern {
  height: 100%;
  width: 100%;
}