@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/elevated-layer" as *;
@use "@/styles/mixins/ellipsis" as *;

.container {
  display: inline-grid;
  grid-template-columns: 1fr 1.5rem;
  gap: 0.625rem;
  align-items: center;
  @include ellipsis;
}

.containerIcon {
  grid-template-columns: auto;
}

.icon {
  width: 1.5em;
}
