@use "@/styles/mixins/font" as *;

.upcomingMatchCard__playVideoIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  --play-video-icon-size: 3.75em;
  height: var(--play-video-icon-size);
  width: var(--play-video-icon-size);
  box-shadow: 0 0.25em 0.625em rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.upcomingMatchCard__liveBadge {
  position: absolute;
  top: var(--icons-offset);
  left: var(--icons-offset);
  display: flex;
  padding: 0.25em;
  border-radius: var(--rounded-corner);
  background-color: var(--brand-magenta);
}

.upcomingMatchCard__liveBadgeText {
  color: var(--primary-background-color);
  text-transform: uppercase;
  font-size: 0.625em;
  @include bold;
}