@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/title-bold" as *;
@use "@/styles/typography/title-medium" as *;

.upcomingMatchesFilterLabel {
  --selected-border-color: var(--active-element-highlight-color);

  background: var(--primary-background-color);
  border: 1px solid var(--divider-color);
  border-radius: var(--rounded-corner);
  padding: 0.0625em 1.1875em;
  transition: border-color ease-in-out 250ms, box-shadow ease-in-out 250ms;
  white-space: nowrap;

  @include desktop-and-up {
    padding-top: 0.125em;
    padding-bottom: 0.125em;
  }
}

.upcomingMatchesFilterLabelText {
  @extend .title-8-medium;
  text-transform: uppercase;
}

.upcomingMatchesFilterRadio {
  &:checked + .upcomingMatchesFilterLabel {
    border: 2px solid var(--selected-border-color);
    padding-left: 1.125em;
    padding-right: 1.125em;

    & .upcomingMatchesFilterLabelText {
      @extend .title-8-bold;
    }
  }

  &:not(:checked) + .upcomingMatchesFilterLabel:hover {
    cursor: pointer;
    box-shadow: 0 0.3125em 0.75em rgba(0, 0, 0, 0.1);
    border-color: var(--label-text-color);
  }
}