@use "@/styles/mixins/line-clamp" as *;

.container {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas:
    "title"
    "provider";
  text-decoration: none;
}

.title {
  @include line-clamp(2);
  grid-area: title;
  line-height: 1.5rem;
}

.provider {
  text-transform: capitalize;
  color: var(--label-text-color);
  grid-area: provider;
}